<template>
  <v-carousel
    class="carousel"
    height="600"
    hide-delimiters
    :show-arrows="false"
    style="overflow: unset"
  >
    <v-carousel-item class="carousel-item background">
      <div class="d-flex fill-height max-width" style="flex-direction: column">
        <div class="d-flex fill-height align-center justify-center">
          <v-col>
            <v-card-title class="px-0 pb-0">
              <span class="img-title mb-lg-3">
                <span class="tertiary--text"
                  >Groot denken<br />
                  voor de kleintjes</span
                >
              </span>
            </v-card-title>
            <v-card-title class="px-0 pt-0"> </v-card-title>
            <span class="img-subtitle tertiary--text">
              Wij zijn Bammetje, een initiatief dat ervoor wil zorgen dat er geen
              enkel kind met honger op school zit.
            </span>
          </v-col>
        </div>
        <div
          class="d-flex justify-center"
          :class="$vuetify.breakpoint.mdAndUp ? `mb-4` : ``">
          <v-card :outlined="$vuetify.breakpoint.smAndDown" tile max-width="800">
            <v-card-title>Help jij ook mee?</v-card-title>
            <v-card-text class="informative-text">
              Steun ons mee en help kinderen die zelf geen eten hebben op
              school. Meld je nu aan om bij te dragen aan een betere wereld!
            </v-card-text>
            <v-card-actions
              class="ml-2 mb-2"
              :class="$vuetify.breakpoint.mdAndUp ? `` : `justify-center`"
            >
              <v-btn class="primary text-none" width="180" tile text large>
                Meld je aan
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "HomeCarousel",
  methods: {},
};
</script>

<style>
.carousel-item > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/banner.png");
  background-size: cover;
  background-position: 50% 100%;
  z-index: -1;
}

.img-subtitle {
  font-size: 1.2em;
  line-height: 1;
  font-weight: 400;
  word-wrap: break-word;
  max-width: 480px;
  display: inline-block;
}
</style>
