<template>
  <v-app class="general">
    <page-header />

    <v-main class="tertiary textcolor--text">
      <router-view />
    </v-main>

    <page-footer />
  </v-app>
</template>

<script>
import PageHeader from "./components/Header";
import PageFooter from "./components/Footer";

export default {
  name: "App",
  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style>
.main-background {
  background-color: #fafafa !important;
}

.v-main__wrap{
  background-color: #fafafa !important;
}

.informative-text {
  font-weight: 300 !important;
  font-size: 1.2em !important;
  line-height: 1.2 !important;
}

.img-title {
  font-size: 2em;
  line-height: 1;
  font-weight: 700;
}

.general {
  font: Roboto;
}
</style>
