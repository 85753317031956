<template>
  <div>
    <v-navigation-drawer
      v-show="$vuetify.breakpoint.mdAndDown"
      v-model="showDrawer"
      absolute
      style="position: fixed; top: 0; right: 0; overflow-y: scroll"
      temporary
      right
      class="primary"
    >
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item-group>
          <v-list-item
            v-for="(menuItem, index) in menuItems"
            :key="index"
            class="tertiary--text informative-text"
            @click="
              closeSideMenu();
              navigateAndScroll(menuItem, $vuetify);
            "
          >
            <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="white">
      <v-container
        style="position: relative"
        class="d-flex max-width"
        :class="$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-0'"
      >
        <div
          @click="!isHomePage() ? $router.push(`/`) : $vuetify.goTo(`#top`)"
          class="hover-cursor"
        >
          <v-img
            contain
            class="my-2"
            src="../assets/header-icon.png"
            transition="scale-transition"
            width="180"
          />
        </div>

        <v-spacer></v-spacer>

        <div v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            v-for="(menuItem, index) in menuItems"
            :key="index"
            text
            small
            @click="navigateAndScroll(menuItem, $vuetify)"
          >
            <span class="textcolor--text text-none">{{ menuItem.name }}</span>
          </v-btn>
          <v-btn text fab>
            <v-icon color="primary">mdi-account-circle-outline</v-icon>
          </v-btn>
        </div>
        <v-app-bar-nav-icon
          v-else
          @click.stop="showDrawer = !showDrawer"
          class="my-2"
        >
        </v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  methods: {
    isHomePage() {
      return this.$route.path == "/";
    },
    navigateAndScroll(menuItem, vuetify) {
      if (this.$route.path !== menuItem.path) {
        this.$router.push(menuItem.path);
        setTimeout(function () {
          if (!menuItem.section) return;
          vuetify.goTo(menuItem.section);
        }, 1000);
      } else if (menuItem.section) {
        vuetify.goTo(menuItem.section);
      }
    },
    closeSideMenu() {
      this.showDrawer = false;
    },
  },
  data() {
    return {
      showDrawer: false,
      menuItems: [
        { name: "Meld je aan", section: null, path: "/" },
        { name: "Dit geloven wij", section: "#belief", path: "/" },
        { name: "Onze aanpak", section: "#approach", path: "/" },
        { name: "Partners", section: "#partners", path: "/" },
        { name: "Over ons", section: null, path: "/about" },
        // { name: "Donate", section: "donate", path: "home" },
        { name: "FAQ", section: null, path: "/faq" },
        { name: "Contact", section: null, path: "/contact" },
      ],
    };
  },
};
</script>

<style>
.hover-cursor:hover {
  cursor: pointer;
}

.max-width {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
</style>
