<template>
  <div class="main-background">
    <HomeCarousel id="top"></HomeCarousel>
    <v-row class="py-4 justify-center max-width" id="belief">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="lawRuneIcon"></v-img>
          Dit is wat wij geloven
        </v-card-title>
        <v-card-text class="informative-text">
          Een vierde van het voedsel in Nederland wordt jaarlijks verspild.
          Tegelijkertijd lijdt 1 op 10 de kinderen honger, omdat ze te weinig te
          eten krijgen.
        </v-card-text>
        <v-card-text class="informative-text">
          Zijn wij in staat om onze manier van leven dusdanig te veranderen
          zodat iedereen er beter van wordt? <br />Wij van Bammetje geloven van
          wel.
        </v-card-text>
        <v-card-text class="informative-text">
          Wij willen dat de ontwikkeling van kinderen altijd voorop staat. Goed
          onderwijs verandert namelijk de toekomst voor iedereen.
        </v-card-text>
        <v-row class="justify-center mt-4">
          <v-col cols="12" sm="4">
            <v-row class="pb-0 justify-center">
              <v-img
                max-width="160"
                :src="require(`@/assets/landing-page-images/statistics1.svg`)"
              />
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-card-title
                  color="secondary"
                  class="title-text justify-center font-weight-bold headline"
                >
                  <div class="primary--text text-center">
                    <span class="primary--text">1 op de 10 kinderen<br /></span>
                    <span class="textcolor--text">groeit op in armoede</span>
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <v-row class="pb-0 justify-center">
              <v-img
                max-width="160"
                :src="require(`@/assets/landing-page-images/statistics2.svg`)"
              />
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-card-title
                  color="secondary"
                  class="title-text justify-center font-weight-bold headline"
                >
                  <div class="primary--text text-center">
                    <span class="primary--text"
                      >25% van het voedsel <br
                    /></span>
                    <span class="textcolor--text"
                      >wordt jaarlijks verspild</span
                    >
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <img
      class="max-height-image"
      :src="require(`@/assets/landing-page-images/cutting_bammen.png`)"
    />
    <v-row class="py-4 justify-center max-width" id="approach">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="supportIcon"></v-img>
          Dit willen wij aanpakken
        </v-card-title>

        <v-card-text class="informative-text">
          Wij geloven in de kracht van communities en partnerships. Door scholen
          in contact te brengen met donateurs in hun buurt kan hier in onze ogen
          wat aan worden gedaan.
        </v-card-text>
        <v-card-text class="informative-text">
          Een school kan een aanvraag indienen met hoeveel kinderen er die dag
          eten nodig hebben, omdat ze geen of te weinig eten vanuit huis
          meekrijgen. Een donateur in de buurt kan deze aanvraag bekijken en
          bepalen of en aan hoeveel van deze verzoeken er voldaan kan worden.
        </v-card-text>

        <v-card-text class="informative-text">
          Op deze manier wordt er een sterke band met de buurt opgebouwd en
          wordt daarnaast voedselverspilling tegengegaan. Ieder kind verdient
          een gelijke kans.
        </v-card-text>

        <v-card-text class="informative-text">
          En dat is precies waar Bammetje voor staat.
        </v-card-text>
      </v-col>
    </v-row>
    <img
      class="max-height-image"
      :src="require(`@/assets/landing-page-images/children_lunchboxes.png`)"
    />

    <v-row class="py-4 justify-center max-width" id="explanation">
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="phoneIcon"></v-img>
          Wat is Bammetje precies?
        </v-card-title>

        <v-card-text class="informative-text">
          Bammetje is een applicatie waar vraag en aanbod samen moeten komen.
          Met behulp van de app kan anoniem worden doorgegeven hoeveel kinderen
          er naar school komen met een tekort aan eten. De app maakt
          inzichtelijk hoeveel maaltijden er voor die dag aan de school moeten
          worden gedoneerd.
        </v-card-text>
        <v-card-text class="informative-text">
          Op deze manier vindt er geen verspilling plaats en kan hetgeen wat
          over is aan andere kinderen op andere scholen worden gedoneerd. De app
          is gebouwd om ervoor te zorgen dat kinderen zich kunnen focussen op
          zaken waar ze wél invloed op hebben: hun schoolwerk én plezier maken.
        </v-card-text>

        <v-card-text class="informative-text">
          Kinderen zijn de toekomst, geef onze toekomst een eerlijke kans.
        </v-card-text>

        <v-card-actions class="informative-text pl-4">
          <span class="text-start justify-start"> Begin nu met Bammetje! </span>
        </v-card-actions>
        <v-card-actions class="justify-center">
          <v-btn
            class="primary text-none text--center justfy-center"
            width="180"
            tile
            text
            large
          >
            Meld je aan
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <FoodCounter></FoodCounter>
    <img
      class="max-height-image front"
      :src="require(`@/assets/landing-page-images/happy_kids.png`)"
    />
    <v-row
      class="py-4 justify-center max-width"
      id="partners"
      v-if="this.partners.length > 0"
    >
      <v-col class="center">
        <v-card-title color="secondary" class="title-text">
          <v-img max-width="50" class="mr-2" :src="peopleIcon"></v-img>
          Onze partners
        </v-card-title>
      </v-col>
    </v-row>
    <v-row class="justify-center max-width">
      <v-flex wrap v-for="(partner, index) in partners" :key="index">
        <v-layout justify-center>
          <v-card width="180" flat class="text-center main-background">
            <v-card class="justify-center" flat @click="goToSite(partner.site)">
              <v-img
                class="main-background"
                contain
                :src="require(`../assets/partners/` + partner.image)"
                transition="scale-transition"
              />
            </v-card>
            <v-card-text
              class="font-weight-bold"
              @click="goToSite(partner.site)"
            >
              {{ partner.name }}
            </v-card-text>
          </v-card>
        </v-layout>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import HomeCarousel from "../components/HomeCarousel";
import FoodCounter from "../components/FoodCounter";
import lawRuneIcon from "@/assets/landing-page-images/icons/law_rune.svg";
import heartIcon from "@/assets/landing-page-images/icons/heart_icon.svg";
import phoneIcon from "@/assets/landing-page-images/icons/phone_icon.svg";
import supportIcon from "@/assets/landing-page-images/icons/support_icon.svg";
import peopleIcon from "@/assets/landing-page-images/icons/people_icon.svg";

export default {
  name: "HomeView",
  components: { HomeCarousel, FoodCounter },
  data() {
    return {
      lawRuneIcon,
      heartIcon,
      phoneIcon,
      supportIcon,
      peopleIcon,
      partners: [
        {
          name: "Niet Graag Een Lege Maag",
          image: "ngelm.png",
          site: "https://ngelm.nl/",
        },
        // {
        //   name: "Alliantie Kinderarmoede",
        //   image: "alliantie_kinderarmoede.png",
        //   site: "https://alliantiekinderarmoede.nl/",
        // },
        // {
        //   name: "Compete IT | Solutions",
        //   image: "compete.png",
        //   site: "https://compete.nl/",
        // },
        // {
        //   name: "Eerlijke Start",
        //   image: "s.png",
        //   site: "https://www.stichtingeerlijkestart.nl/",
        // },
        // {
        //   name: "Tommy Tomato",
        //   image: "tommy_tomato.png",
        //   site: "https://www.tommytomato.nl/",
        // },
        // {
        //   name: "Lunchmaatjes",
        //   image: "lunchmaatjes.png",
        //   site: "https://www.lunchmaatjes.nl/",
        // },
        // {
        //   name: "Armoede Fonds",
        //   image: "armoede_fonds.png",
        //   site: "https://www.armoedefonds.nl/",
        // },
        // {
        //   name: "Kinderhulp",
        //   image: "kinder_hulp.png",
        //   site: "https://kinderhulp.nl/",
        // },
        // {
        //   name: "Nationaal Schoolontbijt",
        //   image: "nationaal_school_ontbijt.png",
        //   site: "https://www.schoolontbijt.nl/",
        // },
        // {
        //   name: "Sam&",
        //   image: "samen.png",
        //   site: "https://www.samenvoorallekinderen.nl/",
        // },
        // {
        //   name: "Save the Children",
        //   image: "save_the_children.png",
        //   site: "https://www.savethechildren.net/",
        // },
        // {
        //   name: "VoedselEducatie010",
        //   image: "voedseleducatie_010.png",
        //   site: "https://voedseleducatie010.nl/",
        // },
      ],
    };
  },
  methods: {
    goToSite(url) {
      window.location.href = url;
    },
  },
};
</script>

<style>
.max-width {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.max-height-image {
  object-fit: cover;
  width: 100%;
  max-height: 360px;
  min-height: 240px;
}
</style>
